<template>
  <UCard
    class="ring-0 shadow-none flex-1 flex flex-col min-w-1 min-h-1"
    :ui="{
      body: {
        base: 'overflow-auto min-h-96',
      },
    }"
  >
    <template #header>
      <BoardSettingsHeader hash="settings-statuses" title="Statuses" :last-page="lastPage" />
    </template>
    <div class="space-y-6">
      <StatusSettingsType
        v-for="statusType in STATUS_TYPE_CHOICES"
        :key="statusType.value"
        :status-type="statusType"
        :parent-id="parentId"
        :source="source"
        :statuses="statuses"
        @created="() => refetch()"
      />
    </div>
  </UCard>
</template>

<script lang="ts" setup>
import { STATUS_TYPE_CHOICES } from '#status/constant'

const props = defineProps<{
  parentId: string
  source: MorphSource
}>()

const route = useRoute()
const { result, refetch } = useStatusesQuery({
  parentId: props.parentId || route.query.id as string,
  source: props.source,
})

const statuses = computed(() => {
  return [...(result.value?.statuses || [])].sort(sortPos)
})

const lastPage = computed(() => props.source === 'settings-pack' ? 'settingsPack' : 'index')
</script>
